// Override default variables before the import
// you can open the bootstrap.scss module and check any available variables.
$primary: #362ec4;
$secondary: #8f5325;
$success: #3e8d63;
$info: #7854e4;
$warning: #b8c924;
$danger: #d62518;
$light: #f8f9fa;
$dark: #090909;

//custom color to be mapped
$sap : #c780fa;
$saplight : #ffcefe;
$sapdark : #6f1ab6;
$sapturq : #aae3e2;
$saporange : #ef9a53;
$sapred : #ff0032;

// this is used to map colors. change accordingly.
$theme-colors: (
  primary: $primary,
  secondary: $secondary,
  success: $success,
  info: $info,
  warning: $warning,
  danger: $danger,
  light: $light,
  dark: $dark,
// add any additional color below
  sap: $sap,
  saplight: $saplight,
  sapdark: $sapdark,
  sapturq: $sapturq,
  saporange: $saporange,
  sapred : $sapred
// now bg-accent,btn-accent,etc.. can be called
);


$nav-pills-link-active-bg : $sap;
$form-select-focus-border-color : $info;
